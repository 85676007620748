export enum BtnStyleType {
  BTN_PRIMARY = 'BTN_PRIMARY',
  BTN_SUBMIT = 'BTN_SUBMIT',
  BTN_DANGER = 'BTN_DANGER',
  BTN_DARK = 'BTN_DARK',
  BTN_DISABLED = 'BTN_DISABLED',
  BTN_ONLYFONT = 'BTN_ONLYFONT',
  BTN_SUBMITCOMMENT = 'BTN_SUBMITCOMMENT',
  BTN_LIKE = 'BTN_LIKE',
  BTN_APPLY = 'BTN_APPLY',
  BTN_NOTEXTAPPLY = 'BTN_NOTEXTAPPLY',
  BTN_COMMUNICATE = 'BTN_COMMUNICATE',
  BTN_GOTOLIST = 'BTN_GOTOLIST',
}
