export const getRangePagination = (page: number, size: number) => {
  const limit = size ? +size : 3;
  const from = page ? page * limit : 0;
  const to = page ? from + size - 1 : size - 1;

  return { from, to };
};

// myPage
export const NUMBER_OF_ITEMS = 12;
